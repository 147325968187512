/**
 * Makes an API call.
 * @param {string} command - The command to be sent to the API.
 * @param {Object} otherData - Additional data to be included in the API call.
 * @returns {Promise} A promise that resolves to the API response.
 */
import { ajax } from "/WebInterface/new-ui/assets/js/utils/ajax.js";
export const apiCall = (command, otherData = {}) => {
  return new Promise((resolve, reject) => {
    const c2f = utils.cookie.get("currentAuth");
    if (c2f) {
      ajax
        .call({
          data: {
            command: command,
            c2f,
            ...otherData,
          },
        })
        .then((resp) => {
          const xml = new DOMParser().parseFromString(resp, "text/xml");
          const result = utils.xmlToJson(xml);
          resolve(result);
        })
        .fail((err) => {
          console.log(err);
          reject(err);
        });
    } else {
      reject({ message: "Not logged in" });
    }
  });
};

/**
 * Retrieves the user's information.
 * @returns {Promise} A promise that resolves to the user's information.
 */
export const getUserInfo = () => {
  return new Promise((resolve, reject) => {
    apiCall("getUserInfo")
      .then((result) => {
        if (result && result.userInfo) {
          const { username } = result.userInfo;
          if (username) {
            resolve(result.userInfo);
          } else {
            reject();
          }
        } else {
          reject();
        }
      })
      .catch((ex) => reject(ex));
  });
};

/**
 * Checks if the user is logged in.
 * @returns {Promise} A promise that resolves to the login result if the user is logged in.
 */
export const isLoggedIn = () => {
  return new Promise((resolve, reject) => {
    apiCall("getUsername")
      .then((result) => {
        if (result && result.loginResult) {
          const { response } = result.loginResult;
          if (response == "success") {
            resolve(result.loginResult);
          } else {
            reject();
          }
        } else {
          reject();
        }
      })
      .catch((ex) => reject(ex));
  });
};

/**
 * Logs out the user.
 * @param {string} redirectLocation - The location to redirect to after logout.
 * @returns {Promise} A promise that resolves after the user is logged out.
 */
export const doLogout = (redirectLocation = "/") => {
  return new Promise((resolve, reject) => {
    apiCall("logout")
      .then(() => {
        utils.cookie.remove("currentAuth");
        window.location = redirectLocation;
      })
      .catch((ex) => {
        reject(ex);
      })
      .finally(() => {
        resolve();
      });
  });
};

const currentListingAPICalls = [];

/**
 * Retrieves the listing from the given path.
 * @param {string} path - The path of the listing.
 * @returns {Promise} A promise that resolves to the listing data.
 */
export const getListingFromPath = (path = "/", abortExisting = true) => {
  return new Promise((resolve, reject) => {
    if (abortExisting) {
      currentListingAPICalls.forEach((xhr) => xhr.abort());
      currentListingAPICalls.length = 0;
    }

    const xhr = ajax
      .call({
        data: {
          command: "getXMLListing",
          format: "JSONOBJ",
          path: utils.encodeURILocal(path.endsWith("/") ? path : path + "/"),
        },
        timeout: 10000000,
      })
      .then((data) => {
        const index = currentListingAPICalls.indexOf(xhr);
        if (index !== -1) {
          currentListingAPICalls.splice(index, 1);
        }
        if (data) {
          resolve(data);
        } else {
          if (index === -1) {
            reject();
          }
        }
      })
      .catch((ex) => {
        const index = currentListingAPICalls.indexOf(xhr);
        if (index !== -1) {
          currentListingAPICalls.splice(index, 1);
        }
        if (index === -1) {
          reject(ex);
        }
      });
    currentListingAPICalls.push(xhr);
  });
};

/**
 * Retrieves the custom form with the given name.
 * @param {string} formName - The name of the custom form.
 * @returns {Promise} A promise that resolves to the custom form data.
 */
export const getCustomForm = (formName = "messageForm") => {
  return new Promise((resolve, reject) => {
    apiCall("getCustomForm", { form: formName })
      .then((result) => {
        if (result?.customForm) {
          resolve(result.customForm);
        } else {
          reject();
        }
      })
      .catch((ex) => reject(ex));
  });
};

/**
 * Posts the data to a custom form.
 * @param {Object} data - The data to be posted to the custom form.
 * @returns {Promise} A promise that resolves after the form is submitted successfully.
 */
export const postCustomForm = (data = {}) => {
  return new Promise((resolve, reject) => {
    apiCall("messageForm", data)
      .then((result) => {
        if (result.commandResult?.response === "Success") {
          resolve(result);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Posts the data to perform a search.
 * @param {Object} data - The data to be used for the search.
 * @returns {Promise} A promise that resolves to the search result.
 */
export const postSearchForm = (data = {}) => {
  return new Promise((resolve, reject) => {
    const c2f = utils.cookie.get("currentAuth");
    ajax
      .call({
        data: {
          command: "search",
          c2f,
          ...data,
        },
        timeout: 10000000,
      })
      .then((result) => {
        if (result) {
          resolve(result);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Changes the user's password.
 * @param {Object} data - The data containing the new password.
 * @returns {Promise} A promise that resolves to the result of changing the password.
 */
export const changePassword = (data = {}) => {
  return new Promise((resolve, reject) => {
    const c2f = utils.cookie.get("currentAuth");
    ajax
      .call({
        data: {
          command: "changePassword",
          c2f,
          ...data,
        },
      })
      .then((resp) => {
        if (resp) {
          const xml = new DOMParser().parseFromString(resp, "text/xml");
          const result = utils.xmlToJson(xml);
          resolve(result);
        } else {
          reject();
        }
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

/**
 * Changes the user's phone number.
 * @param {Object} data - The data containing the new phone number.
 * @returns {Promise} A promise that resolves to the result of changing the phone number.
 */
export const changePhone = (data = {}) => {
  return new Promise((resolve, reject) => {
    const c2f = utils.cookie.get("currentAuth");
    ajax
      .call({
        data: {
          command: "changePhone",
          c2f,
          ...data,
        },
      })
      .then((resp) => {
        if (resp) {
          resolve(resp);
        } else {
          reject();
        }
      })
      .catch((ex) => {
        reject(ex);
      });
  });
};

/**
 * Confirms a QR code.
 * @param {Object} data - The data containing the QR code information.
 * @returns {Promise} A promise that resolves to the confirmation result.
 */
export const confirmQR = (data = {}) => {
  return new Promise((resolve, reject) => {
    apiCall("confirmQR", data)
      .then((result) => {
        if (
          result.commandResult &&
          result.commandResult.response === "Success"
        ) {
          resolve(result.commandResult.response);
        } else {
          reject(result.commandResult.response);
        }
      })
      .catch((ex) => reject(ex));
  });
};

/**
 * Resets the user's password.
 * @param {Object} data - The data containing the necessary information for password reset.
 * @returns {Promise} A promise that resolves to the result of resetting the password.
 */
export const resetPassword = (data = {}) => {
  return new Promise((resolve, reject) => {
    const c2f = utils.cookie.get("currentAuth");
    ajax
      .call({
        data: {
          command: "request_reset",
          ...data,
        },
        urlEncoded: true,
      })
      .then((resp) => {
        if (resp) {
          const xml = new DOMParser().parseFromString(resp, "text/xml");
          const result = utils.xmlToJson(xml);
          resolve(result);
        } else {
          reject();
        }
      })
      .catch((error) => reject(error));
  });
};

const callApiAndResolve = (command, data = {}) => {
  return new Promise((resolve, reject) => {
    apiCall(command, data)
      .then((result) => {
        resolve(result.commandResult.response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAltDomainToken = () =>
  callApiAndResolve("loginDomain1", { random: Math.random() });

export const reportAProblem = (data = {}) => {
  return new Promise((resolve, reject) => {
    apiCall("problemEvent", data)
      .then((result) => {
        if (result.commandResult?.response === "SUCCESS") {
          resolve(result);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getPreview = (data = {}) => {
  return new Promise((resolve, reject) => {
    apiCall("getPreview", data)
      .then((result) => {
        if (result.commandResult?.response === "SUCCESS") {
          resolve(result);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const setPreview = (data = {}) => {
  return new Promise((resolve, reject) => {
    apiCall("setPreview", data)
      .then((result) => {
        if (result.commandResult?.response === "SUCCESS") {
          resolve(result);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const batchComplete = (data = {}) => {
  return new Promise((resolve, reject) => {
    const c2f = utils.cookie.get("currentAuth");

    ajax
      .call({
        data: {
          command: "batchComplete",
          c2f,
        },
      })
      .then((resp) => {
        resolve(resp);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getSearchStatus = (data = {}) => {
  return new Promise((resolve, reject) => {
    apiCall("getSearchStatus", data)
      .then((result) => {
        if (result) {
          resolve(result);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getSubscribeReverseNotificationEvents = (data = {}) => {
  return new Promise((resolve, reject) => {
    apiCall("getSubscribeReverseNotificationEvents", data)
      .then((result) => {
        if (result) {
          resolve(result);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const saveSubscribeReverseNotificationEvents = (data = {}) => {
  return new Promise((resolve, reject) => {
    apiCall("saveSubscribeReverseNotificationEvents", data)
      .then((result) => {
        if (result) {
          resolve(result);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};
